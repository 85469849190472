const sectionThreeObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Exclusive Access',
    headLine: 'Unlimited Transactions with ZERO Fees',
    description: 'Get access to our exclusive card, which allows you to send an unlimited number of transactions without incurring any fees.',
    buttonLabel: 'Get Started Today',
    imgStart: true,
    img: 'images/svg-1.svg',
    alt: 'Credit Card'
}

const sectionOneObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Join Us Today',
    headLine: 'Shinzu Spending!',
    description: 'Instant Reload Bitcoin Debit Card with Unlimited Cash Withdrawal.',
    buttonLabel: 'Boss Do Giveaway',
    imgStart: true,
    img: 'images/svg-main.svg',
    alt: 'Credit Card'
}

const sectionTwoObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Trust. Convenience. Reliability.',
    headLine: 'CONTACT US',
    description: [
        'Contact us: support@shenzuspending.com           ',
    ],
    buttonLabel: 'Contact',
    imgStart: false,
    img: 'images/AdobeStock_72571020_login.svg',
    alt: 'Credit Card',
    hideButton: true
}

const sectionFourObj = {

    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Shenzu Spending',
    headLine: 'PARTNER WITH US',
    description: 'Schedule a meeting with us to build better software: https://calendly.com/shenzu/30min',
    buttonLabel: 'Open a Shenzu Account',
    imgStart: false,
    img: 'images/Shenzu_4.png',
    alt: 'Credit Card',
    hideButton: true
}

export {sectionOneObj, sectionTwoObj, sectionThreeObj, sectionFourObj}
